// + de paramètres possible sur https://swiperjs.com/demos et sur https://swiperjs.com/swiper-api
var swiper1 = new Swiper('.widgetAuto-swiper', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.widgetAuto-pagination',
        clickable: true,
        dynamicBullets: true
    },
    breakpoints: {
        576: {
            slidesPerView: 2
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3
        },
        1200: {
          slidesPerView: 3
        },
        1440: {
          slidesPerView: 4
        },
        1920: {
          slidesPerView: 6
        }
    }
});

var swiper2 = new Swiper('.crossSelling-swiper', {
    spaceBetween: 40,
    loop: true,
    slidesPerView: 1,
    pagination: {
        el: '.crossSelling-pagination',
        clickable: true
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 10
        },
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        },
        1200: {
          slidesPerView: 3
        },
        1440: {
          slidesPerView: 4,
            spaceBetween: 20
        },
        1920: {
          slidesPerView: 5,
          spaceBetween: 20
        }
    }
});


var swiper3 = new Swiper(".widgetActualite-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet widgetActualite-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active widgetActualite-bullet--active',
        clickable: true
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    }
});

/**
 * carrousel images fiche auto
 */
var swiperAuto1 = new Swiper(".webAutoFiche__swiper__thumbnails", {
    loop: true,
    spaceBetween: 40,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".webAutoFiche__swiper__next",
      prevEl: ".webAutoFiche__swiper__prev",
    },
  });
var swiperAuto2 = new Swiper(".webAutoFiche__swiper__img", {
    loop: true,
    spaceBetween: 10,
    
    thumbs: {
      swiper: swiperAuto1,
    },
});

var swiper1 = new Swiper(".diaporama", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
        el: '.diaporama-pagination',
        clickable: true
    },
});



/*var swiper8 = new Swiper('.widgetAutoVIP-swiper', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});*/


/*var swiper2 = new Swiper(".widgetCatalogue-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
        clickable: true
    },
    navigation: {
        nextEl: ".widgetCatalogue-buttonNext",
        prevEl: ".widgetCatalogue-buttonPrev",
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});*/


/*var swiper4 = new Swiper(".ficheProduit-swiper", {
    spaceBetween: 15,
    slidesPerView: 3,
    navigation: {
        nextEl: ".ficheProduit-buttonNext",
        prevEl: ".ficheProduit-buttonPrev",
    },
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
        clickable: true
    },
    breakpoints: {
        370: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        }
    }
});*/

/*var swiper5 = new Swiper('#galerie-slider', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.widgetGalerie-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.widgetGalerie-button-next',
        prevEl: '.widgetGalerie-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});*/

/*var swiper6 = new Swiper('#recrutement-slider', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});*/


/* var swiper9 = new Swiper(".widgetWebimmo-swiper", {
  slidesPerView: 1,
  spaceBetween: 30,
  watchSlidesProgress: true,
  slideVisibleClass: 'widgetWebimmo-slide--visible',

  pagination: {
      el: ".widgetWebimmo-pagination",
      bulletClass: 'swiper-pagination-bullet widgetWebimmo-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active widgetWebimmo-bullet--active'
  },
  breakpoints: {
      768: {
          slidesPerView: 2,
      },
      992: {
          slidesPerView: 3,
      }
  }
}); */